import {
	createPinia
} from 'pinia';//引入pinia
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';//引入持久化插件


//自动注入所有表 pinia 模块
const files = import.meta.glob('./module/*.js',{eager:true})
const modules = {}
Object.keys(files).forEach((key) => {
	// console.log('key:',files[key].default);
	modules[key.replace(/(.*\/)*([^.]+).*/gi, '$2')] = files[key].default
})
// console.log('store modules;',modules);

export const setupPinia = (app) => {
	// console.log('app:',app);
	const pinia = createPinia()//创建pinia实例
	// console.log('plugin pinia;',piniaPluginPersistedstate);
	pinia.use(piniaPluginPersistedstate)//将插件添加到pinia实例上
	app.use(pinia)//注册pinia到vue App上
}

export default (name)=>{
	// console.log('pinia store name;',name);
	return modules[name]()
}

// const store = createPinia()
// store.use(piniaPluginPersistedstate)
// export default store
