import { defineStore } from 'pinia'
import i18n from "@/i18n/index.js";
const { t, locale } = i18n.global;

export const useLangStore = defineStore('localLang', {
  state: () => {
    console.log('init localLang')
    console.log('localstorage lang:',localStorage.getItem('lang'));
    console.log('env lang:',import.meta.env.VITE_LANG);
    
    return {
      // language:localStorage.getItem('lang')||import.meta.env.VITE_LANG,
      language:localStorage.getItem('lang')||localStorage.setItem('lang',import.meta.env.VITE_LANG),

    }
  },
  actions: {
    /**
     * 全局切换语言
     * @param {语言字段} data 
     * @returns 返回设置后的语言
     */
    changeLang(data) {
      if(this.language==data)return
      this.language = data
      locale.value = this.language
      localStorage.setItem('lang',this.language)
    },
  },
  persist: true,
})