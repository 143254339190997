import { getAction, postAction, menuGetAction } from "@/request/menage.js";
export default {

  /**
   * 箱子类别
   * @returns 
   */
  getBoxCategory: () => getAction("/label"),

  /**
   * 获取箱子图片
   * @param {*} box_id 箱子id 
   * @returns 
   */
  getBoxImg: (params) => getAction("/getBox", params),

  /**
   * 箱子列表
  * @param {*} tag 箱子类别标签id,传空值为全部 
   * @returns 
   */
  gethotbox: (params) => getAction("/hotbox", params),

  /**
   * 箱子详情
   * @param {*} id  箱子id 
   * @param {*} userSeed  用户种子 
   * @returns 
   */
  getBoxDetail: (params) => getAction("/box/detail", params),


/**
 * 开箱记录
 * @param {*} id  箱子id
 * @returns 
 */
  getBoxHistoryList: (params) => getAction("/openhistory", params),

  /**
   * 开箱
   * @param {*} id 箱子id 
   * @param {*} num 开箱数量
   * @param {*} userSeed 用户种子 
   * @returns 
   */
  handleOpenBox: (params) => postAction("/open", params),

  /**
   * 新手开箱
   * @param {*}  id 箱子id
   * @param {*}  userSeed 用户种子
   * @returns 
   */
  newOpen: (params) => postAction('/new_open', params),

  /**
   * 免费试玩开箱
   * @param {*} id 箱子id 
   * @param {*} num 开箱数量
   * @param {*} userSeed 用户种子 
   * @returns 
   */
  freeTrial: (params) => getAction("/trial", params),

  //喜报


}