//对战store
import { defineStore } from "pinia";
import { getCurrentInstance } from "vue";
// const {proxy} = getCurrentInstance()
export const arena = defineStore('arena', {
  state: () => {
    return {
      roundNum: 0,//对战回合数
      VsKaiShi: {},//开始对战
      VsJieShu: {},//结束对战
      KaiShiVsData: {},//开始对战数据
      JieShuVsData: {}//结束对战数据
      , AddInformation: {}//加入房间信息
      , fightAnimateVisible: false//对战动画
      ,pingInfoMation: {}//ping信息
      ,endGameInfo:{}
    }
  },
  actions: {
    //加入房间信息
    AddInformationFn(val) {
      // console.warn('arenastore join arena info:', val);
      this.AddInformation = val
    },
    //ping信息
    pingInfoMationFn(val) {
      // console.warn('arenastore join arena info:', val);
      this.pingInfoMation = val
    },
    //回合
    GetroundNum(val) {
      this.roundNum = val
      console.log('回合计算了', val);
    },
    //开始
    GetVsKaiShi(val) {
      this.VsKaiShi = val
      console.log('GetVsKaiShi', val);
    },
    //开始对战数据
    GetKaiShiVsData(val) {
      console.log('GetKaiShiVsData', val);
      this.KaiShiVsData = val
    },
    //结束对战数据
    GetJieShuVsData(val) {
      this.roundNum = 0
      this.JieShuVsData = val
      console.log('this.JieShuData:', this.JieShuVsData);
      // console.log('thisJieShuData',this.JieShuData);
    },
    endGame(val){
      //对战结束返回info
      console.log('对战动效结束,请求接口,返回对战结束,更新大厅对战列表',this.roundNum);
      this.endGameInfo = val
    },
    //结束
    GetVsJieSHu(val) {
      console.log('GetVsJieSHu:', val);
      this.VsJieShu = val
    },
    //对战动画结束
    duizhanAnimatejieshu(val) {
      console.log('对战动画结束', val);
      this.fightAnimateVisible = val
    }
  }
})
export default arena