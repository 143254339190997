<template>
  <div class="footer-container">
    <div class="footer">
      <div class="block-l">
        <div class="mine">
          <div class="logo">
            <!-- <img src="@/assets/images/systemicon/logos.svg" alt=""> -->
          </div>
          <div class="about">
            <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut</div>
            <div>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi,
              incidunt.
            </div>
            <div>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat,
              aspernatur!
            </div>
          </div>
        </div>
        <!-- 协议 联系我们等 -->
        <div class="help-dev">
          <a href="#">联系我们</a>
          <a href="#">用户协议</a>
          <a href="#">隐私协议</a>
          <a href="#">帮助解答</a>
        </div>
        <div class="triple-logo"></div>
      </div>
      <div class="block-r">
        <div class="site-info">
          <div class="info" v-for="item in siteInfoG" :key="item.id">
            <siteInfo v-bind="item"></siteInfo>
          </div>
        </div>
        <!-- 国旗 语言 -->
       

        <div class="langrage-block">
          <a
            @click="taggleLan(key)"
            :href="'/' + key"
            class=""
            data-v-5b69547f=""
            v-for="(nationItem, key) in nationImgModal"
            :key="key"
          >
            <img :src="nationItem" alt="en 语言" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import siteInfo from "@/components/common/siteInfo.vue";
import nationImgModal from "@/utils/dynamicImg.js";
import { ref, getCurrentInstance } from "vue";
import { useLangStore } from "@/store/module/i18n.js";
const { proxy } = getCurrentInstance();
const lanStore = useLangStore();
// 全局站点公开信息 $myt
const siteInfoG = ref([
  {
    id: 1,
    message: "用户",
    type: "user",
    icon: proxy.$getImageUrlByNewURL("systemicon/userg.png"),
    total: 4272454,
  },
  {
    id: 2,
    message: "战斗",
    icon: proxy.$getImageUrlByNewURL("systemicon/battleg.png"),
    type: "battle",
    total: 4272454,
  },
  {
    id: 3,
    message: "升级",
    icon: proxy.$getImageUrlByNewURL("systemicon/updateg.png"),
    type: "upgrade",
    total: 4272454,
  },
  {
    id: 4,
    message: "武器箱",
    icon: proxy.$getImageUrlByNewURL("systemicon/keyg.png"),
    type: "box",
    total: 4272454,
  },
]);

//点击图片切换对应语言
const taggleLan = (lanfield) => {
  console.log("lan:", lanfield);
  lanStore.changeLang(lanfield);
};

//动态导入国旗图片
const nationflag = ref();
// Object.keys(nationflag).forEach(key=>{
//   console.log('flag:',key);
// })

console.log("dynamic:", nationImgModal);
for (const key in nationImgModal) {
  // console.log('keyz:',key)
}
// console.log('dynamic:',nationflag.value)
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
