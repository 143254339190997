<template>
  <div class="wrapper">
    <div class="icon">
      <img :src="$dynamicImgSrc(icon)" alt="" />
      <!-- <img src="@/assets/images/systemicon/userg.png" alt="" /> -->
    </div>
    <div class="info">
      <div class="fwb">{{ total.toLocaleString() }}</div>
      <div class="fwb" :style="{ color: fontColor(type) }">{{ message }}</div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
const props = defineProps(["icon", "message", "total", "type"]);

//根据icon类型定义对应颜色值
const colorObj = {
  user: "#D936BB",
  battle: "#36CED9",
  upgrade: "#AD36D9",
  box: "#D93667",
};

//根据传给的type输出对应的颜色值
const fontColor = computed(() => {
  return (type) => {
    return colorObj[type];
  };
});

//抽离 动态渲染图片路径,非空校验
const $dynamicImgSrc = computed(() => {
  return (url) => {
    return url ? url : "";
  };
});
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  .icon {
    margin-right: 17px;
    img {
      height: 39px;
      display: block;
    }
  }
  .info {
    div {
      font-size: 16px;
      // font-weight: bold;
      color: #fff;
    }
    div + div {
      font-size: 14px;
    }
  }
}
</style>
