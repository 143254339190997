<!--Steam登录弹框-->
<template>
  <div class="steam">
    <myModal
      class="steam-login"
      :title="localTitle"
      :visible="visible"
      @close="close($event)"
    >
      <template #body>
        <div class="def-login" v-if="localContentType == 'def'">
          <div class="options">
            <a-button class="stean-btn cbtn-def" @click="doLogin('steam', $event)">
              <template #icon>
                <img
                  class="btn-icon"
                  src="@/assets/images/index/login_steam.png"
                  alt=""
                />
                <span class="btn-txt">{{ $t("steamLogin") }}</span>
              </template>
            </a-button>
            <a-button
              class="stean-btn cbtn-def cbtn-blue"
              @click="doLogin('email', $event)"
            >
              <template #icon>
                <img
                  class="btn-icon"
                  src="@/assets/images/index/login_email.png"
                  alt=""
                />
                <span class="btn-txt">{{ $t("emailLogin") }}</span>
              </template>
            </a-button>
            <div class="reg-info">
              <span class="sp1">{{ $t("noAccount") + "?" }}</span>
              <span class="link" @click="switchReg">{{ $t("register") }}</span>
            </div>
          </div>
          <div class="l-w agreement">
            <!--<div类=“d1”>
请接受条款并单击下面的按钮通过 Steam 登录。
</div>-->
            <div class="d2">
              <!-- <a-checkbox v-model:checked="term1">
                I agree with the
                <span class="link">Terms of Service,Privacy Policy</span>and
                <span class="link">Cardholder Agreement</span>
              </a-checkbox> -->
              <a-checkbox v-model:checked="term1">
                {{ $t("isAgree") }}
              </a-checkbox>
              <a-checkbox v-model:checked="term2">
                {{ $t("already18") }}
              </a-checkbox>
            </div>
            <!--<a-button class="l-btn" @click="login">登录</a-button>-->
          </div>
        </div>
        <div
          class="register"
          v-if="localContentType == 'reg' || localContentType == 'reset'"
        >
          <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-item v-bind="validateInfos.emailAccount">
              <a-input
                v-model:value="modelRef.emailAccount"
                placeholder="Please input your emialAccount"
                @blur="validate('emailAccount', { trigger: 'blur' }).catch(() => {})"
              />
            </a-form-item>
            <a-form-item v-bind="validateInfos.password">
              <a-input
                v-model:value="modelRef.password"
                placeholder="Please input your password"
                @blur="validate('password', { trigger: 'blur' }).catch(() => {})"
                @validator="
                  validate('password', {
                    validator: (e) => console.log('pp', e),
                  }).catch(() => {})
                "
              />
            </a-form-item>
            <a-form-item v-bind="validateInfos.repeatPassword">
              <a-input
                v-model:value="modelRef.repeatPassword"
                placeholder="Please enter your password again"
                @blur="
                  validate('repeatPassword', { trigger: 'blur' })
                    .then((res) => {
                      console.log('res;', res);
                    })
                    .catch(() => {})
                "
              />
            </a-form-item>
            <a-form-item v-bind="validateInfos.verifyCode">
              <a-input-group>
                <a-input
                  v-model:value="modelRef.verifyCode"
                  placeholder="Please input your verifyCode"
                  @blur="validate('verifyCode', { trigger: 'blur' }).catch(() => {})"
                />
                <a-button class="cbtn-blue verify-btn" @click="sendVerify('unlogin')">{{
                  $t("send")
                }}</a-button>
              </a-input-group>
            </a-form-item>
            <a-form-item :wrapperCol="{ span: 24 }">
              <a-button class="cbtn-def" @click="handleClick(localContentType)">{{
                btnMsg
              }}</a-button>
            </a-form-item>
          </a-form>
        </div>
        <!-- <div class="reset_passwprd" v-if="localContentType == 'reset'"></div> -->
        <div class="emial_login" v-if="localContentType == 'email'">
          <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane key="1" :tab="$t('verifCode')">
              <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-item v-bind="validateInfos.emailAccount">
                  <a-input
                    v-model:value="modelRef.emailAccount"
                    placeholder="Please input your emialAccount"
                  />
                </a-form-item>
                <a-form-item v-bind="validateInfos.verifyCode">
                  <a-input-group>
                    <a-input
                      v-model:value="modelRef.verifyCode"
                      placeholder="Please input your verifyCode"
                    />
                    <a-button class="cbtn-blue verify-btn" @click="sendVerify('login')">{{
                      $t("send")
                    }}</a-button>
                  </a-input-group>
                </a-form-item>
                <a-form-item class="find"><span class="forget"></span></a-form-item>
                <a-form-item :wrapperCol="{ span: 24 }">
                  <a-button class="cbtn-def loginBt" @click="emialLogin('verify')">{{
                    $t("login")
                  }}</a-button>
                </a-form-item>
              </a-form>
            </a-tab-pane>
            <a-tab-pane key="2" :tab="$t('password')">
              <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-item v-bind="validateInfos.emailAccount">
                  <a-input
                    v-model:value="modelRef.emailAccount"
                    placeholder="Please input your emialAccount"
                  />
                </a-form-item>
                <a-form-item v-bind="validateInfos.password">
                  <a-input
                    v-model:value="modelRef.password"
                    placeholder="Please input your password"
                  />
                </a-form-item>
                <a-form-item class="find"
                  ><span class="forget" @click="switchFindPwd"
                    >{{ $t('forgetPassword') }}</span
                  ></a-form-item
                >
                <a-form-item :wrapperCol="{ span: 24 }">
                  <a-button class="cbtn-def loginBt" @click="emialLogin('pwd')">
                    {{$t('login')}}</a-button
                  >
                </a-form-item>
              </a-form>
            </a-tab-pane>
          </a-tabs>
        </div>
      </template>
    </myModal>
  </div>
</template>

<script setup>
import {
  ref,
  getCurrentInstance,
  reactive,
  toRaw,
  computed,
  watchEffect,
  onMounted,
} from "vue";
import customMessage from "@/lib/custom/scrollTipBar.js";
import showNotice from "@/components/common/notice/notice.js";
import $api from "@/api/index.js";
import $store from "@/store";
import { Form } from "ant-design-vue";
import handleResponse from "@/utils/handleResponseData.js";
import i18n from "@/i18n";
const { t: $t, locale } = i18n.global;
const useForm = Form.useForm;
// // 自定义校验规则函数
// const validateUsername = (rule, value) => {
//   if (!value || value.length < 6) {
//     return Promise.reject('用户名长度至少为 6 个字符');
//   }
//   return Promise.resolve();
// };
// form.setFields([
//   {
//     name: 'username',
//     rules: [
//       { validator: validateUsername }
//     ]
//   }
// ]);
const userStore = $store("user");

const labelCol = {
  span: 4,
};

//表单撑满父盒子
const wrapperCol = {
  span: 24,
};

//表单数据
const modelRef = reactive({
  emailAccount: '',
  password: '',
  repeatPassword: "",
  verifyCode: "",
});
let validatePass2 = async (_rule, value) => {
  console.log("ru;", _rule, value);
  if (value === "") {
    return Promise.reject("Please input the password again");
  } else if (value !== modelRef.password) {
    console.log("err");
    return Promise.reject("Two inputs don't match!");
  } else {
    return Promise.resolve();
  }
};

//表单字段规则校验
const rulesRef = reactive({
  emailAccount: [
    {
      required: true,
      message: "Please input emialAccount",
      trigger: "blur",
    },
  ],
  password: [
    {
      required: true,
      message: "Please input password",
      trigger: "blur",
    },
  ],
  repeatPassword: [
    {
      required: true,
      message: "Please input repeatPassword",
      trigger: "blur",
      // validator: validatePass2,
    },
    {
      validator(_, value) {
        // console.log("test;", _, value);
        // 自定义校验逻辑
        // if (value && value.length < 5) {
        //   return Promise.reject("The two password entries are inconsisten");
        // }
        // if (value && value!=modelRef.password) {
        //   return Promise.reject("The two password entries are inconsisten");
        // }
        if (value === "") {
          return Promise.reject("Please input the password again");
        } else if (value !== modelRef.password) {
          console.log("err");
          return Promise.reject("Two inputs don't match!");
        } else {
          return Promise.resolve();
        }
        // return Promise.resolve();
      },
    },
  ],
  verifyCode: [
    {
      required: true,
      message: "Please input verifyCode",
      trigger: "blur",
    },
  ],
});
const { resetFields, validate, validateInfos } = useForm(modelRef, rulesRef, {
  onValidate: (...args) => {
    // console.log("args;", args);
  },
  validateInfos: (args) => {
    // console.log("info;", args);
  },
});

const props = defineProps({
  visible: {
    type: Boolean,
    desc: "登录弹框开关",
    default: false,
  },
  title: {
    type: String,
    desc: "登录弹框标题",
    default: "steam 登录",
  },
  contentType: {
    type: String,
    desc: "登录弹框,可选值为 def  reg  reset  email",
    default: "",
  },
});

//登录弹框内容类型 def-默认  reg-注册/找回密码页 email-邮箱验证码/密码登录页
const localContentType = ref(props.contentType);
//弹框标题
const localTitle = ref(props.title);
const emits = defineEmits(["close", "login", "reg", "rest", "email"]);
const term1 = ref(!false); //协议
const term2 = ref(!false); //是否成年
//watchEffect(()=>{
//console.log('cont:',props.contentType);
//})
//计算注册与找回密码显示文本
const btnMsg = computed(() => {
  switch (localContentType.value) {
    case "reg":
      // return flag.value ? "Confirm" : "Register";
      return flag.value ? $t('confirm') : $t('register');
    case "reset":
      return $t('confirm');
  }
});
//是否同意协议
function permit() {
  if (term1.value && term2.value) {
    return true;
  }
  showNotice("f", { desc: "请先勾选协议" });
  return false;
}

//注册与找回密码页提交按钮
function handleClick(type) {
  console.log("type:", type);
  if ("reg" == type) {
    if (flag.value) {
      console.log("找回密码");
      findPwd();
      return;
    }
    //注册
    console.log("注册");
    doRegister();
  }
}

//通过邮箱注册账号
function doRegister() {
  console.log("注册api");
  validate()
    .then(() => {
      console.log(toRaw(modelRef));
      console.log("校验通过");
      console.log("request api...");
    })
    .catch((err) => {
      console.log("error", err);
      console.log("校验失败");
    });

  const params = {
    email: modelRef.emailAccount ,
    password: modelRef.password,
    verify: modelRef.verifyCode,
  };
  // return 
  $api.user.emailReg(params).then((res) => {
    const resp = handleResponse(res);
    if (!resp) return console.log("未知错误", res.message);
    //注册成功返回token
    LoginSuccessUpdate(resp);
  });

  // emailReg
}

//找回密码
function findPwd() {
  console.log("找回密码api");
  const params = {
    email: modelRef.emailAccount ,
    password: modelRef.password,
    verify: modelRef.verifyCode,
  };
  $api.user.resetEmialPassword(params).then((res) => {
    const resp = handleResponse(res);
    if (!resp) return console.log("未知错误", res.message);
    showNotice("s", { desc: res.message });
  });
}

//发送验证码
function sendVerify(type) {
  let is_use = "";
  //邮箱验证码登录
  if ("login" == type) {
    is_use = 2; //登录
    console.log("邮箱验证码登录");
  }
  if ("unlogin" == type) {
    //邮箱注册或者找回密码
    if (flag.value) {
      console.log("邮箱找回密码验证码");
      is_use = 3; //修改密码
    } else {
      is_use = 1; //注册
      console.log("邮箱注册验证码");
    }
  }
  console.log("邮箱验证码 is_use", is_use);
  $api.user.sendEmailVerify({ email: modelRef.emailAccount , is_use }).then((res) => {
    console.log("发送邮箱验证码", res);
    const resp = handleResponse(res);
    if (!resp) return console.log("未知错误", res.message);
    showNotice("s", { desc: res.message });
  });
}

//默认显示steam登录和邮箱登录按钮
function doLogin(type, e) {
  if (!permit()) {
    //Console.log("未授权");
    return;
  }
  // console.log("param:", type, e);
  e.stopPropagation();
  //通过steam登录
  if ("steam" == type) {
    // console.log("steam login");
    steamLogin();
  }

  //前往邮箱登录页面
  if ("email" == type) {
    localContentType.value = "email";
    localTitle.value = $t('emailLoginLarge');
    // emits("email", 100);
    //邮箱登录();
  }
}

//登录注册成功后更新站点数据
function LoginSuccessUpdate(res) {
  localStorage.setItem("token", res.access_token);
  showNotice("s", { desc: $t('loginSuccess') });
  //登录成功更新用户数据
  const token = localStorage.getItem("token");
  if (token) {
    userStore.$patch((state) => {
      state.isToken = true;
      state.count++;
      //tag  更新组件key  强制重新渲染组件 显示loading?
    });
    userStore.getUserInfo().then((res) => {
      console.log("登录成功,更新用户数据;", res);
    });
  }
  //更新数据后关闭登录弹框
  emits("close");
}

//steam登录 //bug 未实现
function steamLogin() {
  console.log("steam login");
  // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLmhvdGFwcC5vcmdcL2FwaVwvc3RlYW1cL2hhbmRsZSIsImlhdCI6MTcyNzE2OTI3OCwiZXhwIjoxNzI3MzQ5Mjc4LCJuYmYiOjE3MjcxNjkyNzgsImp0aSI6ImNURVppbWFIQzljZDYwNjUiLCJzdWIiOjEsInBydiI6Ijg3ZTBhZjFlZjlmZDE1ODEyZmRlYzk3MTUzYTE0ZTBiMDQ3NTQ2YWEifQ.7z7iGsMa_0Zqa--6gGFKtpCvmskL0o9Hg8qXcIvcnjI";
  // localStorage.setItem("token", token);

  // return
  $api.user.loginSteam().then((res) => {
    console.log("steam res;", res);
    // https://steamcommunity.com/openid/login?openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&openid.mode=checkid_setup&openid.return_to=http%3A%2F%2Fapi.hotapp.org%2Fapi%2Fsteam%2Fhandle&openid.realm=http%3A%2F%2Fapi.hotapp.org&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select
    window.location.href = res.url;
  });
}

onMounted(() => {
  handleLoginResponse();
});

async function handleLoginResponse() {
  // 这里可以使用一个 AJAX 请求去获取 token 信息 //bug  怎么拿到token???
  const response = await fetch(window.location.href); ////bug 地址栏 这里不知道怎么写
  console.log("response;", response);
  if (response) {
    const data = await response.json();
    // 保存 token 和用户信息
    // localStorage.setItem("token", data);

    // 假设你有一个存储用户信息的变量
  } else {
    // 错误处理
  }
}

//邮箱登录(验证码登录和密码登录)
function emialLogin(type) {
  if ("verify" == type) {
    console.log("verify login");
    loginByVerifyForEmail();
  }
  if ("pwd" == type) {
    console.log("pwd login");
    loginByPwdForEmail();
  }
}

//邮箱验证码登录
function loginByVerifyForEmail() {
  const params = {
    email: modelRef.emailAccount ,
    verify: modelRef.verifyCode,
  };
  $api.user.vemail_login(params).then((res) => {
    const resp = handleResponse(res);
    if (!resp) return console.log("未知错误", res.message);
    //邮箱验证码登录成功返回token
    LoginSuccessUpdate(resp);
  });
}

//邮箱密码登录
function loginByPwdForEmail() {
  const admin = {
    //手机：“13732906529”，
    //密码：“a123456”，
    email: modelRef.emailAccount ,
    password: modelRef.password ,
  };
  $api.user.emailLogin(admin).then((res) => {
    console.log("密码登录:", res);
    const resp = handleResponse(res);
    if (!resp) return console.log("未知错误", res.message);
    LoginSuccessUpdate(resp);
  });
}

//邮箱登录页默认选中验证码登录
const activeKey = ref("1");

//切换到注册页
function switchReg() {
  if (!permit()) {
    //Console.log("未授权");
    return;
  }
  localContentType.value = "reg";
  localTitle.value = $t('register');
}

const flag = ref(false); //是否是找回密码页面
//切换到找回密码页
function switchFindPwd() {
  if (!permit()) {
    //Console.log("未授权");
    return;
  }
  console.log("find");
  localContentType.value = "reg";
  localTitle.value = $t('forgetPasswordLarge');
  flag.value = true;
}

const login = (e) => {
  e.stopPropagation();

  if (!permit()) {
    //Console.log("未授权");
    return;
  }
  console.log("do login");
  const muni = Math.random();
  if (muni > 1) {
    showNotice("f", { desc: "login failed" });
    return emits("login");
  }

  //$api.user.loginSteam().then((res) => {
  //console.log("密码登录:", res);
  //if (res.code == 200) {

  //window.location.href = res.url
  //return
  //localStorage.setItem("token", res.data.access_token);
  //showNotice('s',{desc:"登录成功"});
  ////登录成功更新用户数据
  //const token = localStorage.getItem("token");
  //if (token) {
  //userStore.$patch((state) => {
  //state.isToken = true;
  //state.count++
  ////tag  更新组件key  强制重新渲染组件 显示loading?
  //});
  //userStore.getUserInfo().then((res) => {
  //console.log("登录成功,更新用户数据;", res);
  //});
  //}
  ////更新数据后关闭登录弹框
  //emits("close");
  //}
  //}).catch(error=>{
  ////console.log('error stean:',error);
  //});

  //返回
  const admin = {
    //手机：“13732906529”，
    //密码：“a123456”，
    email: "1883013@gmail.com",
    password: "172319h1bIR3P778",
  };

  $api.user.emailLogin(admin).then((res) => {
    console.log("密码登录:", res);
    if (res.code == 200) {
      localStorage.setItem("token", res.data.access_token);
      showNotice("s", { desc: "登录成功" });
      //登录成功更新用户数据
      const token = localStorage.getItem("token");
      if (token) {
        userStore.$patch((state) => {
          state.isToken = true;
          state.count++;
          //tag  更新组件key  强制重新渲染组件 显示loading?
        });
        userStore.getUserInfo().then((res) => {
          console.log("登录成功,更新用户数据;", res);
        });
      }
      //更新数据后关闭登录弹框
      emits("close");
    }
  });

  //账号密码登录 测试
  //$api.user.login(admin).then((res) => {
  //console.log("密码登录:", res);
  //if (res.code == 200) {
  //localStorage.setItem("token", res.data.access_token);
  //showNotice("s", { desc: "登录成功" });
  ////登录成功更新用户数据
  //const token = localStorage.getItem("token");
  //if (token) {
  //userStore.$patch((state) => {
  //state.isToken = true;
  //state.count++;
  ////tag  更新组件key  强制重新渲染组件 显示loading?
  //});
  //userStore.getUserInfo().then((res) => {
  //console.log("登录成功,更新用户数据;", res);
  //});
  //}
  ////更新数据后关闭登录弹框
  //emits("close");
  //}
  //});
};
//更新用户数据

//关闭登录弹框
function close(e) {
  e.stopPropagation();
  emits("close");
}
</script>
<style lang="scss" scoped>
//表单样式
.ant-form {
  //边框：2px 纯黄色；
  .ant-form-item {
    //边框：2px 纯红色；
    margin-bottom: 16px;
    .ant-input {
      height: 50px;
      border-radius: 25px;
      background-color: #050812;
      color: #8f9eb5;
      //边框：2px 纯绿色；
    }
    .ant-input::placeholder {
      //颜色：红色！重要；
      color: #363e53 !important;
    }
  }
}

.steam-login {
  :deep(.ant-modal) {
    //宽度：650px！重要；
    height: 470px;

    .ant-modal-content {
      height: inherit;
    }

    .ant-modal-close {
      top: 15px;
    }
    .ant-modal-header {
      height: 50px;
      background-color: #172438;
      .ant-modal-title {
        padding-left: 31px;
        justify-content: flex-start;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .ant-modal-body {
      height: 420px;
      background-color: #0e121e;
      padding: 42px 60px 46px 60px;

      //note 抽离按钮通用样式  宽度 背景色 icon等
      .cbtn-def {
        width: 400px;
        height: 50px;
        border-radius: 25px;
        background-color: $active-color;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
        position: relative;
        .btn-icon {
          top: 50%;
          left: 30px;
          transform: translateY(-50%);
          position: absolute;
          width: 30px;
          height: 30px;
        }
        .btn-txt {
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
        }
      }
      .cbtn-blue {
        background-color: #0057e6;
      }
      .verify-btn {
        z-index: 1;
        width: 90px;
        height: 50px;
        border-radius: 25px;
        font-size: 20px;
        font-weight: bold;
      }
      .ant-input-group {
        display: flex;
        position: relative;
        .verify-btn {
          position: absolute;
          right: 0;
        }
      }

      //默认
      .def-login {
        width: 400px;
        .options {
          width: 400px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 191px;
          margin-bottom: 40px;
          .reg-info {
            cursor: pointer;
            text-align: center;
            .sp1 {
              color: #343c50;
            }
            .link {
              text-decoration: underline;
              color: $active-color;
            }
          }
        }
        //协议
        .d1,
        .d2,
        .ant-checkbox-wrapper {
          color: #fff;
          font-weight: 400;
          font-size: 14px;
        }
        .d2 {
          .link {
            text-decoration: underline;
            font-size: bold;
          }
          .ant-checkbox-wrapper {
            //颜色：#fff；
            margin-bottom: 14px;
          }
          .ant-checkbox-wrapper + .ant-checkbox-wrapper {
            margin: 0;
          }
        }
        .l-w {
          position: relative;
        }
        .agreement {
        }
        .l-btn {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 110px);
          width: 180px;
          height: 50px;
          border-radius: 25px;
          background-color: $active-color;
          font-size: 20px;
          font-weight: bold;
        }
      }

      //注册
      .register {
        width: 400px;
        //边框：2px 纯红色；
        //背景颜色：#fff；
        .cbtn-def {
          margin-top: 26px;
        }
      }

      //找回密码
      .reset_passwprdz {
      }

      //邮箱登录与验证码登录
      .emial_login {
        width: 400px;
        .find {
          .ant-form-item-control-input-content {
            text-align: right;
          }
          .forget {
            cursor: pointer;
            color: #343c50;
          }
        }
        .loginBt {
          position: absolute;
          top: 0;

          //顶部边距：66px；
        }
      }
    }
  }
}
</style>
