import $api from "@/api";
function PostBlind_box_channel(id) {
    console.log("join box channel webSocket_id;", id);
    $api.battle.joinChannel({ client_id: id }).then((res) => {
        console.log("加入频道;", res);
    });
}

// var md5 = require("blueimp-md5");

/****** WebSocket 工具类 ******/
/**
 * 为保证 this 指向
 * 内部方法声明使用 方法名(){} 的形式
 * 内部事件声明使用 方法名=()=>{} 的形式
 * 外部事件声明随意
 */
import md5 from 'blueimp-md5';
var MySocket = /** @class */ (function () {
    function MySocket() {
        var _this = this;
        var offset = 0;
        this.getOffset = function (e) {
            return offset;
        };
        /******* WebSocket 事件绑定 *******/
        //开启事件
        this.OnOpen = function (e) {
            // console.log('websocket OnOpen:',);
            _this.CloseInterval();
            _this.OpenInterval();
        };



        //失败事件
        this.OnError = function (e) {
            // console.error('websocket断开OnError:', e);
            // console.log('重新连接 websocket...');
            let count = 0
            const timer = setInterval(() => {
                if (count > 5) {
                    clearInterval(timer)
                }
                _this.Start();
                count++
            }, 2000);

        };
        //接收事件
        this.OnMessage = function (e) {
            // console.log('接收websocket OnMessage e:', e.data);
            var redata = JSON.parse(e.data);
            // console.log('websocket resp:', redata);
            if (redata.hasOwnProperty('code') && redata.code != 200) {
                // console.log("$socket err: code!=200");
            }
            if (redata.hasOwnProperty('MsgType') && redata.hasOwnProperty('Data')) {
                //分发
                // console.log("socket事件分发: MsgType【" + redata.MsgType + "】");
                _this.action[redata.MsgType] && _this.action[redata.MsgType](redata.Data);
                if (redata.MsgType == 'joinGroup') {
                    // console.error('zu;', redata.Data);
                }
                if (redata.MsgType == 'ping') {
                    // console.log('websocket 响应 ping');

                    var nowtime = (new Date).getTime();
                    offset = parseInt((nowtime - redata.Data.server_time * 1000) / 1000);
                }
                if(redata.MsgType == 'connect'){

                }
                // obj.name='''
                // obj.name(oo)
            }
        };
        //关闭事件
        this.OnClose = function (e) {
            // console.warn('websocket close 事件', e);

            _this.CloseInterval();
            _this.Start();
            // console.log('$socket msg: close socket', e);
        };
        /****** 工具类辅助事件 ******/
        //认证
        this.Authen = function (res) {
            // console.log('this;',this);//此处this指向Object对象
            //_this指向MySocket对象
            _this.id = res.client_id;
            sessionStorage.setItem("C_id", _this.id);
            PostBlind_box_channel(_this.id);
            var ran = Math.floor(Math.random() * Math.pow(10, 20)).toString();
            var data = {
                MsgType: 'auth',
                token: '',
                string: ran,
                time: new Date().getTime(),
                app_id: 'csgoskins'
            };
            var temp = _this.id + ran + data.time + data.app_id + 'h2T*V%&cQx6traMr';
            data.token = md5(temp);
            _this.isAuthen = true;
            _this.Send(JSON.stringify(data));
        };
        //开启定时器
        this.OpenInterval = function () {
            // console.error('websocket OnOpen 定时器');

            var time = 0;
            var dur = 200;
            var heart = 6000;
            _this.timer = setInterval(function () {
                time += dur;
                //处理事件
                if (_this.isAuthen && _this.event.length > 0) {
                    // console.log('event[0];',_this.event[0]);
                    _this.event[0](_this.id);
                    _this.event.splice(0, 1);
                }
                //发送心跳
                if (time > heart) {
                    // console.warn('发送心跳');
                    time = 0;
                    _this.Send('z');
                    // console.log('$socket msg: send heart');
                }
            }, dur);
        };
        //关闭定时器
        this.CloseInterval = function () {
            _this.timer && clearInterval(_this.timer);
            _this.timer = null;
        };
        this.wsurl = import.meta.env.VITE_SOCKET_URL;
        this.action = {};
        this.event = [];
        this.Add('connect', this.Authen);
    }
    //添加事件 自定义事件JoinGroup等事件
    MySocket.prototype.Add = function (name, func) {
        if (!this.action[name])
            this.action[name] = func;
        return this;
    };
    //添加事件  websocket 本身事件
    MySocket.prototype.Event = function (func) {
        this.event[this.event.length] = func;
        return this;
    };
    //移除事件
    MySocket.prototype.Remove = function (name) {
        if (this.action[name])
            delete (this.action[name]);
        return this;
    };
    //测试使用
    MySocket.prototype.Test = function () {
        for (var key in this.action) {
            if (Object.prototype.hasOwnProperty.call(this.action, key)) {
                if (key != 'connect')
                    this.action[key]();
            }
        }
    };
    //启动服务
    MySocket.prototype.Start = function () {
        // console.log('websocket 启动服务');

        // this.websock && this.Close();
        this.websock = new WebSocket(this.wsurl);
        this.websock.onopen = this.OnOpen;
        this.websock.onmessage = this.OnMessage;
        this.websock.onerror = this.OnError;
        this.websock.onclose = this.OnClose;
        this.isAuthen = false;
    };
    //发送数据
    MySocket.prototype.Send = function (data) {
        // console.log('websocket 发送数据');
        if (this.websock.readyState == WebSocket.OPEN) {
            this.websock.send(data);
        }
    };
    //关闭服务
    MySocket.prototype.Close = function () {
        // console.log('websocket 关闭服务', this);

        this.action = {};
        this.event = [];
        this.websock && this.websock.close();
    };
    return MySocket;
}());


export default {
    install: app => {
        Object.defineProperty(app.config.globalProperties, '$socket', {
            value: new MySocket(),
            writable: false
        })
    }
}
// exports["default"] = {
//     install: function (Vue) {
//         Object.defineProperty(Vue.prototype, '$socket', {
//             value: new MySocket(),
//             writable: false
//         });
//     }
// };
